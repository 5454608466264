<template>
  <DataTable :options="options" />
</template>

<script>
export default {
  data() {
    return {
      options: {
        hideAction: true,
        collection: 'enquiry-type',
        headers: [
          {
            text: 'Icon',
            value: 'icon',
            width: '5%',
            sortable: false,
            component: { name: 'dt-icon', props: { field: 'icon' } },
          },
          { text: 'Name', value: 'name', width: '20%', filter: true, sortable: false },
          {
            text: 'Profile',
            value: 'account',
            width: '30%',
            component: { name: 'dt-account-name', props: { field: 'account' } },
            sortable: false,
            filter: true,
          },
        ],
      },
    };
  },
  // created() {
  //   this.options.filters = [
  //     {
  //       label: 'Show default keys',
  //       key: 'default',
  //       type: 'switch',
  //     },
  //   ];
  // },
};
</script>
